import {ElMessage} from "element-plus";

export const myElSuccess = (message) => {
    ElMessage({
        showClose: true,
        message: message,
        type: 'success',
    })
}
export const myElError = (message) => {
    ElMessage({
        showClose: true,
        message: message,
        type: 'error',
    })
}
export const myElMessage = (message) => {
    ElMessage({
        showClose: true,
        message: message,
        type: 'warning',
    })
}
export const myElWarning = (message) => {
    ElMessage({
        showClose: true,
        message: message,
        type: 'warning',
    })
}
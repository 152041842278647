import axios from "axios";
import {Promise} from "core-js";
import {ElMessage} from "element-plus";
import {myElError} from "@/js/elmentUtil";
import router from "@/router";

const baseURLs={
    dev: {
        // dataApi:"https://www.zouran.top/Apifox",
        SpringBootApi:"http://localhost:8081",
        imgApi:"http://127.0.0.1:5000"},
    test: {
        SpringBootApi: "https://course.zouran.top/api",
        imgApi:"https://www.zouran.top/pytorch"},
}['test']
const  DEFAULT_CONFIG=(apiName)=> {
    return {
        'timeout': 60 * 1000 ,// 超时
        'baseURL':baseURLs[apiName]
    }
}
const methods=['get','post']
const http={}
http['axios']=function (config) {
    return axios(config)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

for(let i=0;i<methods.length;i++){
    //初始选项创建axios实例,http对象存方法
    http[methods[i]] = function (url, params, type = 'params', baseUrl = 'SpringBootApi') {
        const instance = axios.create(
        DEFAULT_CONFIG(baseUrl)
    )
        //请求前可以进行配置
        instance.interceptors.request.use(
            function (config) {
                if (sessionStorage.getItem("token")) { //判断token是否存在
                    config.headers['token'] = sessionStorage.getItem("token");  //将token设置成请求头
                }
                console.log(config)
                return config;
            },
            function (error) {
                console.log(error);
                return error;
            })
        //请求后可以对结果处理
        instance.interceptors.response.use(
            function (response) {
                if (response.data.code === 200) {
                    ElMessage({
                        showClose: true,
                        message: response.data.message,
                        type: 'success',
                    })
                    return Promise.resolve(response.data)
                }
                if (response.data.code === 401) {
                    router.push('/')
                    myElError(response.data.message)
                    return Promise.reject(response.data)
                } else {
                    ElMessage({
                        showClose: true,
                        message: response.data.message,
                        type: 'error',
                    })
                    return Promise.reject(response.data)
                }
            },
            function (error) {
                let msg = '';
                if (error && error.response) {
                    switch (error.response.status) {
                        case 400:
                            msg = '错误请求';
                            break;
                        case 401:
                            msg = '未授权，请重新登录';
                            break;
                        case 403:
                            msg = '拒绝访问';
                            break;
                        case 404:
                            msg = '请求错误,未找到该资源';
                            break;
                        case 405:
                            msg = '请求方法未允许';
                            break;
                        case 408:
                            msg = '请求超时';
                            break;
                        case 500:
                            if (error.response.data.message !== null) {
                                msg = error.response.data.message;
                            } else msg = '服务器出错!';
                            break;
                        case 501:
                            msg = '服务器无法识别请求方法';
                            break;
                        case 502:
                            msg = '网络错误';
                            break;
                        case 503:
                            msg = '服务不可用';
                            break;
                        case 504:
                            msg = '网络超时';
                            break;
                        case 505:
                            msg = 'http版本不支持该请求';
                            break;
                        default:
                            msg = '未知的错误，请联系管理员！';
                    }

                } else {
                    // 超时处理
                    if (error.message.includes('timeout')) {
                        msg = '服务器响应超时'
                    } else {
                        msg = '未知的错误，请联系管理员'
                    }
                }
                ElMessage({
                    showClose: true,
                    message: msg,
                    type: 'error',
                })
                return Promise.reject(error);
            }
        )
        //请求参数集合
        const config = {
            url: url,
            method: methods[i]
        }
        if (type === "data")
            config['data'] = params
        else config['params'] = params
        return instance.request(config).then(response => {
            return Promise.resolve(response)
        })
            .catch(error => {
                return Promise.reject(error)
            })
    }
}
export {
    baseURLs,
    http
}
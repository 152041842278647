import {baseURLs, http} from "../httpConfig";

export const logInApi = (params) => {
    return http.post('/admin/login', params).then(
        response => {
            return response.data
        }
    )
}
export const checkLogInApi = () => {
    return http.get('/admin/checkLogin').then(
      response=>{return response}
  )
}
export const logOutApi = (params) => {
    return http.get('/admin/logout', params).then(
        response => {
            return response
        }
    )
}
export const counsellorQueryAllApi = () => {
    return http.get('/visitor/counsellor').then(
        response => {
            return response.data
        }
    )
}
export const principalQueryAllApi = () => {
    return http.get('/visitor/principal').then(
        response => {
            return response.data
        }
    )
}
import PrincipalMain from "@/components/principal/Main.vue";
import CounsellorMain from "@/components/counsellor/Main.vue";


import LogIn from "@/components/LogIn.vue";
export const routes=[
    {
        path: '/counsellor',
        name: '',
        component: CounsellorMain
    },
    {
        path: '/principal',
        name: '',
        component: PrincipalMain
    },
    {
        path: '',
        name: '',
        component: LogIn
    }
]
<template>
  <el-container class="layout-container-demo" style="height: 100vh">

    <el-container>
      <el-header style="text-align: right; font-size: 12px; display: flex; align-items: center;">
        <div
            style="display: flex; align-items: center; text-align: left; margin-left: 20px; color: #a96300; font-size: 20px;">
          <div style="margin-right: 5px; margin-top: 5px;">
            <el-icon size="20">
              <UserFilled/>
            </el-icon>
          </div>
          学院师生信息管理系统
        </div>
        <div class="toolbar" style="margin-left: auto;">
          <span style="margin-right: 10px;">你好呀, {{ account.accountName }} </span>
          <el-dropdown>
            <div style="display: flex; align-items: center; margin-right: 20px;">

              <el-avatar
                  :src=account.headSculpture
                  @error="handleImageError()"
              />
            </div>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item>
                  <el-icon>
                    <UserFilled/>
                  </el-icon>
                  个人信息
                </el-dropdown-item>
                <el-dropdown-item @click="logout()">
                  <el-icon>
                    <Back/>
                  </el-icon>
                  退出登录
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </el-header>

      <el-container>
        <el-aside width="200px">
          <el-scrollbar>
            <el-menu :default-openeds="['1', '2']">
              <el-sub-menu index="1">
                <template #title>
                  <el-icon style="margin-right: 18px;">
                    <User/>
                  </el-icon>
                  学生
                </template>
                <el-menu-item index="1-1" @click="controllerMain('student');update('student')">
                  <el-icon>
                    <Document/>
                  </el-icon>
                  学生信息
                </el-menu-item>
              </el-sub-menu>
              <el-sub-menu index="2">
                <template #title>
                  <el-icon style="margin-right: 18px;">
                    <Avatar/>
                  </el-icon>
                  教师
                </template>
                <el-menu-item index="2-1" @click="controllerMain('teacher');update('teacher')">
                  <el-icon>
                    <Document/>
                  </el-icon>
                  教师信息
                </el-menu-item>
              </el-sub-menu>
            </el-menu>
          </el-scrollbar>
        </el-aside>
        <el-main>
          <div style="height: 50vh;margin: 40px;">
            <div v-if="mainContent.student">
              <el-table :data="informationList.student">
                <el-table-column prop="id" label="学号" sortable/>
                <el-table-column prop="name" label="姓名"/>
                <el-table-column prop="sumCredit" label="已修学分" sortable/>
                <el-table-column
                    prop="sumCredit"
                    label="是否达到毕业要求"
                    :filters="Credits"
                    :filter-method="filterCredit"
                    filter-placement="bottom-end">

                  <template #default="scope">
                    <el-tag disable-transitions v-if="scope.row.sumCredit>=targetCredit" type="success">
                      是
                    </el-tag>
                    <el-tag disable-transitions v-else type="danger">
                      否
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column
                    prop="department"
                    label="专业"
                    :filters="departments"
                    :filter-method="filterDepartment"
                    filter-placement="bottom-end">
                  <template #default="scope">
                    <el-tag
                        disable-transitions>{{ scope.row.department }}
                    </el-tag>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div v-if="mainContent.teacher">
              <el-table :data="informationList.teacher">
                <el-table-column prop="id" label="工号" sortable/>
                <el-table-column prop="name" label="姓名"/>
                <el-table-column prop="satisfaction" label="满意度" sortable>
                  <template #default="scope">
                    {{ scope.row.satisfaction }}%
                  </template>
                </el-table-column>
                <el-table-column
                    prop="satisfaction"
                    label="教评等级"
                    :filters="satisfactions"
                    :filter-method="filterSatisfaction"
                    filter-placement="bottom-end">
                  <template #default="scope">
                    <el-tag :type="getTagType(scope.row.satisfaction)">
                      {{ getTagText(scope.row.satisfaction) }}
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column prop="phone" label="联系方式"/>
                <el-table-column
                    prop="title"
                    label="职称"
                    :filters="titles"
                    :filter-method="filterTitle"
                    filter-placement="bottom-end">
                  <template #default="scope">
                    <el-tag
                        disable-transitions>{{ scope.row.title }}
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column
                    prop="department"
                    label="专业"
                    :filters="departmentTeachers"
                    :filter-method="filterDepartment"
                    filter-placement="bottom-end">
                  <template #default="scope">
                    <el-tag
                        disable-transitions>{{ scope.row.department }}
                    </el-tag>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div style="height: 60%;display: flex; justify-content: space-between">
              <div ref="chart1" style="width: 48%; height: 100%; "></div>
              <div ref="chart2" style="width: 48%; height: 100%; "></div>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </el-container>
</template>
<script setup>
import {computed, onMounted, ref} from 'vue'
import {Avatar, Back, Document, User, UserFilled} from '@element-plus/icons-vue'
import * as echarts from 'echarts';
import {
  calculateCategorySum,
  calculateCredit,
  calculateCreditAll,
  calculateSatisfaction,
  calculateUnique
} from '@/js/dataChange'
import {buildSeriesBar, buildSeriesPie} from "@/js/customEcharts";
import {logOutApi, principalQueryAllApi} from "@/axios/api/logIn";
import router from "@/router";

const targetCredit = 13
const chart1 = ref(null);
const chart2 = ref(null);
const informationList = ref({
  "teacher": [
    {
      "phone": "15616633288",
      "name": "李老师",
      "id": 1,
      "department": "软件工程",
      "satisfaction": 98.0,
      "title": "副教授"
    },
    {
      "phone": "15613322256",
      "name": "张老师",
      "id": 2,
      "department": "计算机科学与技术",
      "satisfaction": 99.0,
      "title": "讲师"
    }
  ],
  "student": [
    {
      "name": "张晓年",
      "id": 1,
      "department": "软件工程",
      "sumCredit": 5.0
    },
    {
      "name": "李小玉",
      "id": 2,
      "department": "计算机科学与技术",
      "sumCredit": 5.0
    }
  ]
})
const account = ref({
  'accountNumber': sessionStorage.getItem("accountNumber"),
  'accountName': sessionStorage.getItem("accountName"),
  'headSculpture': sessionStorage.getItem("headSculpture")
})

const getTagType = (satisfaction) => {
  if (satisfaction >= 90) {
    return 'success';
  } else if (satisfaction >= 80) {
    return '';
  } else if (satisfaction >= 60) {
    return 'warning';
  } else {
    return 'danger';
  }
};

const getTagText = (satisfaction) => {
  if (satisfaction >= 90) {
    return '非常满意';
  } else if (satisfaction >= 80) {
    return '满意';
  } else if (satisfaction >= 60) {
    return '一般';
  } else {
    return '不满意';
  }
};
const updateInformationList = () => {
  principalQueryAllApi().then(res => {
    informationList.value = res
  }).catch(err => {
    router.push('')
  })
}

const option1 = ref({
  title: {
    text: '人数统计',
    left: 'center', // 水平居中
    textAlign: 'center' // 文本内容居中
  },
  tooltip: {},
  xAxis: {
    data: ['软件工程', '计算机科学与技术']
  },
  yAxis: {},
  series: [
    {
      name: '通过人数',
      type: 'bar',
      data: [6, 4],
      itemStyle: {
        barBorderRadius: 5,
        borderWidth: 1,
        borderType: 'solid',
        color: '#82ff00',
        borderColor: '#82ff00',
        shadowColor: '#5470c6',
        shadowBlur: 3,
      },
      barWidth: '20%'
    },
    {
      name: '不通过人数',
      type: 'bar',
      data: [1, 1],
      itemStyle: {
        barBorderRadius: 5,
        borderWidth: 1,
        borderType: 'solid',
        color: '#ff0000',
        borderColor: '#82ff00',
        shadowColor: '#5470c6',
        shadowBlur: 3
      }, barWidth: '20%'
    }
  ]
})
const option2 = ref({
  title: {
    text: '人数占比',
    left: 'center', // 水平居中
    textAlign: 'center' // 文本内容居中
  },
  tooltip: {
    trigger: 'item',
    formatter: '{a} <br/>{b} : {c}人 <br/>占比:{d}%'
  },
  series: [
    {
      name: '未达到必修学分人数',
      type: 'pie',
      data: [{
        value: 1,
        name: '计算机科学与技术'
      },
        {
          value: 2,
          name: '软件工程'
        }]
    }
  ],
})
ref({
  title: {
    text: '人数统计',
    left: 'center', // 水平居中
    textAlign: 'center' // 文本内容居中
  },
  tooltip: {},
  xAxis: {
    data: ['教授', '副教授']
  },
  yAxis: {},
  series: [
    {
      name: '人数',
      type: 'bar',
      data: [3, 4],
      itemStyle: {
        barBorderRadius: 5,
        borderWidth: 1,
        borderType: 'solid',
        color: '#ffdd73',
        borderColor: '#82ff00',
        shadowColor: '#5470c6',
        shadowBlur: 3,
      },
      barWidth: '20%'
    }
  ]
});
const currentIndex = ref(-1);

onMounted(() => {
  // 初始化 ECharts 实例
  const myChart1 = echarts.init(chart1.value);
  // 绘制图表
  myChart1.setOption(option1.value);
  // 初始化 ECharts 实例
  const myChart2 = echarts.init(chart2.value);
  // 绘制图表
  myChart2.setOption(option2.value);
  myChart2.on('mousemove', function (params) {
    // 判断是否在柱状图的数据点上
    if (params.seriesType === 'bar' && params.dataIndex !== currentIndex) {
      // 取消之前高亮的图形
      myChart2.dispatchAction({
        type: 'downplay',
        seriesIndex: 0,
        dataIndex: currentIndex
      });

      // 高亮当前图形
      myChart2.dispatchAction({
        type: 'highlight',
        seriesIndex: 0,
        dataIndex: params.dataIndex
      });

      // 显示 tooltip
      myChart2.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,
        dataIndex: params.dataIndex
      });

      // 更新当前索引
      currentIndex.value = params.dataIndex;
    }
  });

// 鼠标移出时取消高亮和隐藏 tooltip
  myChart2.on('mouseout', function () {
    myChart2.dispatchAction({
      type: 'downplay',
      seriesIndex: 0,
      dataIndex: currentIndex
    });
    currentIndex.value = -1;
  });
  update('student')
});
const departments = computed(
    () => {
      return calculateUnique(informationList.value.student, 'department').map(item => ({
        text: item,
        value: item
      }))
    }
)
const departmentTeachers = computed(
    () => {
      return calculateUnique(informationList.value.teacher, 'department').map(item => ({
        text: item,
        value: item
      }))
    }
)
const satisfactions = computed(() => {
  return calculateSatisfaction(informationList.value.teacher).map(item => ({
    text: item,
    value: item
  }))
})

const titles = computed(() => {
      return calculateUnique(informationList.value.teacher, 'title').map(item => ({
        text: item,
        value: item
      }))
    }
)
const mainContent = ref({
  student: true,
  teacher: false,
})
const controllerMain = (target) => {
  // 遍历对象的所有属性
  for (let key in mainContent.value) {
    // 判断属性是否为 'name'
    mainContent.value[key] = key === target;
  }
  // console.log(mainContent.value)
}
const update = (name) => {
  principalQueryAllApi().then(res => {
    informationList.value = res
    if (name === 'student') {
      const data = calculateCreditAll(informationList.value.student, targetCredit)
      option1.value.title.text = "合格人数分布"
      option2.value.title.text = "各专业人数占比"
      option1.value.xAxis.data = data[0]
      option1.value.series = buildSeriesBar([data[1], data[2]], ['通过人数', '不通过人数'], ['20%', '20%'])
      option2.value.series = buildSeriesPie(calculateCredit(informationList.value.student, targetCredit), '未达到必修学分人数')
    } else if (name === "teacher") {
      const data = calculateCategorySum(informationList.value.teacher, "title")
      option1.value.title.text = "师资力量"
      option1.value.xAxis.data = data[0]
      option1.value.series = buildSeriesBar([data[1]], ['合计人数'], ['20%'], [5])
      option2.value.series = buildSeriesPie(data[1], '人数占比')
      option2.value.title.text = "师资比例"
    }
    clearCharts()
  })

}
const clearCharts = () => {
  const myChart2 = echarts.init(chart2.value);
  myChart2.clear()
  myChart2.setOption(option2.value);
  let myChart1 = echarts.init(chart1.value);
  myChart1.clear()
  myChart1.setOption(option1.value);
}

const filterDepartment = (value, row) => {
  return row.department === value;
}
const logout = () => {
  logOutApi({"accountNumber": account.value.accountNumber}).then(() => {
    router.push('/')
  }).catch(err => {
    // myElError(err.message)
  })
}
const filterTitle = (value, row) => {
  return row.title === value;
}
const filterSatisfaction = (value, row) => {
  console.log(value, row.satisfaction - 0 >= 80)
  if (value === '非常满意' && row.satisfaction >= 90) {
    return true;
  } else if (value === '满意' && row.satisfaction >= 80 && row.satisfaction < 90) {
    return true;
  } else if (value === '一般' && row.satisfaction >= 60 && row.satisfaction < 80) {
    return true;
  } else return value === '不满意' && row.satisfaction < 60;

}
const Credits = ref([
  {text: '是', value: 'pass'},
  {text: '否', value: 'fail'},
])
const filterCredit = (value, row) => {
  // 实现具体的筛选逻辑
  if (value === 'pass' && row.sumCredit >= targetCredit) {
    return true;
  } else return value === 'fail' && row.sumCredit < targetCredit;
}
const handleImageError = () => {
  // 图像加载失败时，更新为默认图像
  account.value.headSculpture =
      "https://file.zouran.top/imgs/course/default_head.png";
}
</script>


<style scoped>
.layout-container-demo .el-header {
  position: relative;
  background-color: var(--el-color-primary-light-7);
  color: var(--el-text-color-primary);
}

.layout-container-demo .el-aside {
  color: var(--el-text-color-primary);
  background: var(--el-color-primary-light-8);
}

.layout-container-demo .el-menu {
  border-right: none;
}

.layout-container-demo .el-main {
  margin: 50px;
  border: 1px solid #f6c974; /* Border color and thickness */
  border-radius: 10px;
  padding: 0;
}

.layout-container-demo .el-table {
  height: 45vh; /*设置表格高度 */
}

.layout-container-demo .toolbar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  right: 20px;
}
</style>
<template>
  <el-container class="layout-container-demo" style="height: 100vh">

    <el-container>
      <el-header style="text-align: right; font-size: 12px; display: flex; align-items: center;">
        <div
            style="display: flex; align-items: center; text-align: left; margin-left: 20px; color: #a96300; font-size: 20px;">
          <div style="margin-right: 5px; margin-top: 5px;">
            <el-icon size="20">
              <UserFilled/>
            </el-icon>
          </div>
          学院师生信息管理系统
        </div>
        <div class="toolbar" style="margin-left: auto;">
          <span style="margin-right: 10px;">你好呀, {{ account.accountName }} </span>
          <el-dropdown>
            <div style="display: flex; align-items: center; margin-right: 20px;">

              <el-avatar
                  :src=account.headSculpture
                  @error="handleImageError()"
              />
            </div>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item>
                  <el-icon>
                    <UserFilled/>
                  </el-icon>
                  个人信息
                </el-dropdown-item>
                <el-dropdown-item @click="logout()">
                  <el-icon>
                    <Back/>
                  </el-icon>
                  退出登录
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </el-header>
      <el-container>
        <el-aside width="200px">
          <el-scrollbar>
            <el-menu :default-openeds="['1', '2']">
              <el-sub-menu index="1">
                <template #title>
                  <el-icon style="margin-right: 18px;">
                    <User/>
                  </el-icon>
                  学生
                </template>
                <el-menu-item index="1-1" @click="controllerMain('student');update('student')">
                  <el-icon>
                    <Document/>
                  </el-icon>
                  学生信息
                </el-menu-item>
                <el-menu-item index="1-2" @click="controllerMain('grades');update('grades')">
                  <el-icon>
                    <Tickets/>
                  </el-icon>
                  学生成绩
                </el-menu-item>
              </el-sub-menu>
              <el-sub-menu index="2">
                <template #title>
                  <el-icon style="margin-right: 18px;">
                    <Avatar/>
                  </el-icon>
                  教师
                </template>
                <el-menu-item index="2-1" @click="controllerMain('teacher');update('teacher')">
                  <el-icon>
                    <Document/>
                  </el-icon>
                  教师信息
                </el-menu-item>
              </el-sub-menu>
            </el-menu>
          </el-scrollbar>
        </el-aside>
        <el-main>
          <div style="height: 50vh;margin: 50px;">
            <div v-if="mainContent.student">
              <el-table :data="informationList.student">
                <el-table-column prop="id" label="学号" min-width="100" sortable/>
                <el-table-column prop="name" label="姓名" min-width="80"/>
                <el-table-column prop="sex" label="性别" min-width="50">
                  <template #default="scope">
                    <el-tag
                        disable-transitions :type="scope.row.sex==='男'?'success':''">{{ scope.row.sex }}
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column prop="age" label="年龄" min-width="40" sortable/>
                <el-table-column prop="gradeAverage" label="平均成绩" min-width="50" sortable/>
                <el-table-column prop="phone" label="联系方式" min-width="100"/>
                <el-table-column
                    prop="department"
                    label="专业"
                    :filters="filters"
                    :filter-method="filterTag"
                    filter-placement="bottom-end">
                  <template #default="scope">
                    <el-tag
                        disable-transitions>{{ scope.row.department }}
                    </el-tag>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div v-if="mainContent.teacher">
              <el-table :data="informationList.teacher">
                <el-table-column prop="name" label="姓名" min-width="120"/>
                <el-table-column prop="phone" label="联系方式"/>
                <el-table-column
                    prop="department"
                    label="专业"
                    :filters="teacherFilters"
                    :filter-method="filterTag"
                    filter-placement="bottom-end">
                  <template #default="scope">
                    <el-tag
                        disable-transitions>{{ scope.row.department }}
                    </el-tag>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div v-if="mainContent.grades">
              <el-table :data="computedAllGrades" style="width: 100%">
                <el-table-column label="姓名" prop="name"/>
                <el-table-column label="课程名称" prop="courseName"/>
                <el-table-column label="授课教师" prop="teacherName"/>
                <el-table-column label="学期" prop="term" sortable/>
                <el-table-column label="成绩" prop="score" sortable/>
                <el-table-column label="绩点" prop="gradePoint" sortable/>
                <el-table-column label="学分" prop="credit" sortable/>
                <el-table-column
                    prop="score"
                    label="等级"
                    :filters="filterGrades"
                    :filter-method="filterGrade"
                    filter-placement="bottom-end"
                >
                  <template #default="scope">
                    <el-tag disable-transitions v-if="scope.row.score>=60" type="success">
                      通过
                    </el-tag>
                    <el-tag disable-transitions v-else type="danger">
                      未通过
                    </el-tag>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div style="height: 60%;display: flex; justify-content: space-between">
              <div ref="chart1" style="width: 48%; height: 100%; "></div>
              <div ref="chart2" style="width: 48%; height: 100%; "></div>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </el-container>
</template>
<script setup>
import {computed, onMounted, ref} from 'vue'
import {
  Avatar,
  Back,
  Document,
  House,
  Menu as IconMenu,
  Message,
  Setting, Tickets,
  User,
  UserFilled
} from '@element-plus/icons-vue'
import {
  calculateCredit,
  calculateExcellent,
  calculateNoPass,
  calculateCategorySum,
  calculateCreditAll, calculateUnique
} from "@/js/dataChange";
import * as echarts from "echarts";
import {counsellorQueryAllApi, logOutApi} from "@/axios/api/logIn";
import router from "@/router";
import {myElError} from "@/js/elmentUtil";

const informationList = ref({
  "teacher": [
    {
      "phone": "15616633288",
      "name": "李老师",
      "department": "软件工程"
    },
    {
      "phone": "15613322256",
      "name": "张老师",
      "department": "计算机科学与技术"
    }
  ],
  "student": [
    {
      "phone": "12341122256",
      "sex": "男",
      "allGrades": [
        {
          "name": "张晓年",
          "score": 99.0,
          "courseName": "java课程设计",
          "gradePoint": 4.0,
          "teacherName": "李老师",
          "term": "2023-2024-秋",
          "credit": 2.0,
          "courseId": 1
        },
        {
          "name": "张晓年",
          "score": 87.0,
          "courseName": "javaweb开发教程",
          "gradePoint": 3.7,
          "teacherName": "张老师",
          "term": "2022-2023-秋",
          "credit": 3.0,
          "courseId": 2
        }
      ],
      "name": "张晓年",
      "gradeAverage": "3.82",
      "id": 1,
      "department": "软件工程",
      "age": 20
    },
    {
      "phone": "14512233586",
      "sex": "女",
      "allGrades": [
        {
          "name": "李小玉",
          "score": 90.0,
          "courseName": "java课程设计",
          "gradePoint": 4.0,
          "teacherName": "李老师",
          "term": "2023-2024-春",
          "credit": 2.0,
          "courseId": 1
        },
        {
          "name": "李小玉",
          "score": 84.0,
          "courseName": "javaweb开发教程",
          "gradePoint": 3.3,
          "teacherName": "张老师",
          "term": "2022-2023-春",
          "credit": 3.0,
          "courseId": 2
        }
      ],
      "name": "李小玉",
      "gradeAverage": "3.58",
      "id": 2,
      "department": "计算机科学与技术",
      "age": 19
    }
  ]
})
const account = ref({
  'accountNumber': sessionStorage.getItem("accountNumber"),
  'accountName': sessionStorage.getItem("accountName"),
  'headSculpture': sessionStorage.getItem("headSculpture")
})
const updateInformationList = () => {
  counsellorQueryAllApi().then(res => {
    informationList.value = res
  }).catch(err => {

  })
}

const chart1 = ref(null);
const chart2 = ref(null);

// 计算属性
const computedAllGrades = computed(() => {
  const allGrades = []
  for (let studentElement of informationList.value.student) {
    for (let gradeElement of studentElement.allGrades) {
      allGrades.push(gradeElement)
    }
  }
  return allGrades
})
let chart1_data = ref(calculateCategorySum(informationList.value.student, 'department')[1])
let chart2_data = ref(calculateCategorySum(informationList.value.student, 'sex')[1])
const informationContent = ref({"chart1": '各专业人数占比', "chart2": '男女比例'})
const gradeContent = ref({"chart1": '各科目挂科人数', "chart2": '各科优秀人数'})
const chartContent = ref(informationContent.value)
const option1 = ref({
  title: {
    text: chartContent.value.chart1,
    left: 'center', // 水平居中
    textAlign: 'center' // 文本内容居中
  },
  tooltip: {
    trigger: 'item',
    formatter: '{a} <br/>{b} : {c}人 <br/>占比:{d}%'
  },
  series: [
    {
      name: '人数占比',
      type: 'pie',
      data: chart1_data
    }
  ],
})
const option2 = ref({
  title: {
    text: chartContent.value.chart2,
    left: 'center', // 水平居中
    textAlign: 'center' // 文本内容居中
  },
  tooltip: {
    trigger: 'item',
    formatter: '{a} <br/>{b} : {c}人 <br/>占比:{d}%'
  },
  series: [
    {
      name: '人数占比',
      type: 'pie',
      data: chart2_data
    }
  ],
})
const currentIndex = ref(-1);

onMounted(() => {
  // 初始化 ECharts 实例
  const myChart1 = echarts.init(chart1.value);
  // 绘制图表
  myChart1.setOption(option1.value);
  // 初始化 ECharts 实例
  const myChart2 = echarts.init(chart2.value);
  // 绘制图表
  myChart2.setOption(option2.value);
  myChart2.on('mousemove', function (params) {
    // 判断是否在柱状图的数据点上
    if (params.seriesType === 'bar' && params.dataIndex !== currentIndex) {
      // 取消之前高亮的图形
      myChart2.dispatchAction({
        type: 'downplay',
        seriesIndex: 0,
        dataIndex: currentIndex
      });

      // 高亮当前图形
      myChart2.dispatchAction({
        type: 'highlight',
        seriesIndex: 0,
        dataIndex: params.dataIndex
      });

      // 显示 tooltip
      myChart2.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,
        dataIndex: params.dataIndex
      });
      // 更新当前索引
      currentIndex.value = params.dataIndex;
    }
  });

// 鼠标移出时取消高亮和隐藏 tooltip
  myChart2.on('mouseout', function () {
    myChart2.dispatchAction({
      type: 'downplay',
      seriesIndex: 0,
      dataIndex: currentIndex
    });

    currentIndex.value = -1;
  });
  update('student')
});
const filters = computed(() => {
  return calculateUnique(informationList.value.student, 'department').map(item => ({
    text: item,
    value: item
  }))
})
const teacherFilters = computed(() => {
  return calculateUnique(informationList.value.teacher, 'department').map(item => ({
    text: item,
    value: item
  }))
})
const filterGrades = ref([
  {text: '通过', value: 'pass'},
  {text: '未通过', value: 'fail'},
])
const mainContent = ref({
  student: true,
  teacher: false,
  grades: false,
})
const update = (name) => {
  counsellorQueryAllApi().then(res => {
    informationList.value = res
    if (name === 'student') {
      option1.value.title.text = informationContent.value.chart1
      option2.value.title.text = informationContent.value.chart2
      chart1_data.value = calculateCategorySum(informationList.value.student, 'department')[1]
      chart2_data.value = calculateCategorySum(informationList.value.student, 'sex')[1]
      clearCharts()
    } else if (name === "grades") {
      option1.value.title.text = gradeContent.value.chart1
      option2.value.title.text = gradeContent.value.chart2
      chart1_data.value = calculateNoPass(computedAllGrades.value, 'courseName')
      chart2_data.value = calculateExcellent(computedAllGrades.value)
      clearCharts()
    } else {
      let myChart1 = echarts.init(chart1.value);
      myChart1.clear()
      let myChart2 = echarts.init(chart2.value);
      myChart2.clear()
    }
  })

}
const logout = () => {
  logOutApi({"accountNumber": account.value.accountNumber}).then(() => {
    router.push('/')
  }).catch(err => {
    // myElError(err.message)
  })
}
const clearCharts = () => {
  let myChart1 = echarts.init(chart1.value);
  myChart1.clear()
  myChart1.setOption(option1.value);
  let myChart2 = echarts.init(chart2.value);
  myChart2.clear()
  myChart2.setOption(option2.value);
}

const controllerMain = (target) => {
  // 遍历对象的所有属性
  for (let key in mainContent.value) {
    // 判断属性是否为 'name'
    mainContent.value[key] = key === target;
  }
  // console.log(mainContent.value)
}
const filterTag = (value, row) => {
  return row.department === value;
}
const filterGrade = (value, row) => {
  // 实现具体的筛选逻辑
  if (value === 'pass' && row.score >= 60) {
    return true;
  } else return value === 'fail' && row.score < 60;
}
const handleImageError = () => {
  // 图像加载失败时，更新为默认图像
  account.value.headSculpture =
      "https://file.zouran.top/imgs/course/default_head.png";
}

</script>


<style scoped>
.layout-container-demo .el-header {
  position: relative;
  background-color: var(--el-color-primary-light-7);
  color: var(--el-text-color-primary);
}

.layout-container-demo .el-aside {
  color: var(--el-text-color-primary);
  background: var(--el-color-primary-light-8);
}

.layout-container-demo .el-menu {
  border-right: none;
}

.layout-container-demo .el-main {
  margin: 50px;
  border: 1px solid #f6c974; /* Border color and thickness */
  border-radius: 10px;
  padding: 0;
}

.layout-container-demo .el-table {
  height: 45vh; /*设置表格高度 */
}

.layout-container-demo .toolbar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  right: 20px;
}
</style>
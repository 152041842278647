import { createRouter, createWebHistory } from 'vue-router'
import {routes} from "@/router/routes";
import {checkLogInApi} from "@/axios/api/logIn";
import {myElError} from "@/js/elmentUtil";



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach(function (to, from, next) {
    // 从前端session中获取登录信息
    let token = sessionStorage.getItem('token');
    console.log(to.path)
    // 路由到“网格分配”、“预估AQI”、“历史反馈列表”，是需要判断是否登录
    if (to.path !== '/') {
        if (token == null) {
            // 如果未登录，中断导航并跳转到登录页面
            return router.push('/');
        } else {
            checkLogInApi().then(() => {
                // 继续导航
                next();
            }).catch(() => {
                return router.push('/');
            })
        }
    }
    next();

});
export default router

/**
 * @param {Array} data
 * @param {string} target
 * @return {Array}
 */
export const calculateUnique = (data, target) => {
// 使用 Set 数据结构来存储不重复的 department 值
    const uniqueDepartments = new Set();

// 遍历对象数组，将 department 值加入 Set
    data.forEach(item => {
        uniqueDepartments.add(item[target]);
    });

// 将 Set 转换为数组

    return Array.from(uniqueDepartments)
}
export const calculateCredit = (data, threshold) => {
    let result = calculateUnique(data, 'department')

    // 使用 map 方法将数组转换为对象
    result = Object.fromEntries(
        result.map(department => [department, 0])
    );
    for (let i = 0; i < data.length; i++) {
        if (data[i].sumCredit < threshold) {
            result[data[i]['department']]++
        }
    }
    result = Object.entries(result).map(([key, value]) => ({'name': key, 'value': value}))
    return result
}
export const calculateSatisfaction = (data) => {
    let result = new Set()
    for (let i = 0; i < data.length; i++) {
        if (data[i].satisfaction >= 90) {
            result.add('非常满意');
        } else if (data[i].satisfaction >= 80) {
            result.add('满意');
        } else if (data[i].satisfaction >= 60) {
            result.add('一般');
        } else {
            result.add('不满意');
        }
    }
    return Array.from(result)

}
export const calculateCreditAll = (data, threshold) => {
    let result = calculateUnique(data, 'department')
    let yes = Array(result.length).fill(0);
    let no = Array(result.length).fill(0);
    for (let i = 0; i < data.length; i++) {
        if (data[i].sumCredit < threshold) {
            no[result.indexOf(data[i].department)]++;
        } else yes[result.indexOf(data[i].department)]++;
    }
    return [result, yes, no]
}

export const calculateCategorySum = (data, target) => {
    let result = calculateUnique(data, target)
    const titles = result
    // 使用 map 方法将数组转换为对象
    result = Object.fromEntries(
        result.map(department => [department, 0])
    );
    for (let i = 0; i < data.length; i++) {
        result[data[i][target]]++
    }
    result = Object.entries(result).map(([key, value]) => ({'name': key, 'value': value}));
    return [titles, result]
}
export const calculateNoPass = (data, target) => {
    let result = calculateUnique(data, target)
    // 使用 map 方法将数组转换为对象
    result = Object.fromEntries(
        result.map(department => [department, 0])
    );
    for (let i = 0; i < data.length; i++) {
        if (data[i].score < 60)
            result[data[i][target]]++
    }

    // 使用 Object.entries() 转换对象为键值对数组，然后使用 filter 过滤值不为 0 的项
    const filteredEntries = Object.entries(result).filter(([key, value]) => value !== 0);

    // 使用 Object.fromEntries() 将过滤后的键值对数组转换为对象
    result = Object.fromEntries(filteredEntries);
    result = Object.entries(result).map(([key, value]) => ({'name': key, 'value': value}));
    return result
}

export const calculateExcellent = (data) => {
    let result = calculateUnique(data, 'courseName')
    // 使用 map 方法将数组转换为对象
    result = Object.fromEntries(
        result.map(department => [department, 0])
    );
    for (let i = 0; i < data.length; i++) {
        if (data[i].score >= 90)
            result[data[i]['courseName']]++
    }
    // 使用 Object.entries() 转换对象为键值对数组，然后使用 filter 过滤值不为 0 的项
    const filteredEntries = Object.entries(result).filter(([key, value]) => value !== 0);

    // 使用 Object.fromEntries() 将过滤后的键值对数组转换为对象
    result = Object.fromEntries(filteredEntries);
    result = Object.entries(result).map(([key, value]) => ({'name': key, 'value': value}));
    return result
}


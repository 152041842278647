const option = {
    // 全局调色盘。
    color: [
        '#c23531',
        '#2f4554',
        '#61a0a8',
        '#d48265',
        '#91c7ae',
        '#749f83',
        '#ca8622',
        '#bda29a',
        '#6e7074',
        '#546570',
        '#c4ccd3'
    ],

    series: [
        {
            type: 'bar',
            // 此系列自己的调色盘。
            color: [
                '#3fff00',
                '#fa1b1b',
                '#e69d87',
                '#8dc1a9',
                '#ea7e53',
                '#eedd78',
                '#73a373',
                '#73b9bc',
                '#7289ab',
                '#91ca8c',
                '#f49f42'
            ]
            // ...
        },
        {
            type: 'pie',
            // 此系列自己的调色盘。
            color: [
                '#37A2DA',
                '#32C5E9',
                '#67E0E3',
                '#9FE6B8',
                '#FFDB5C',
                '#ff9f7f',
                '#fb7293',
                '#E062AE',
                '#E690D1',
                '#e7bcf3',
                '#9d96f5',
                '#8378EA',
                '#96BFFF'
            ]
            // ...
        }
    ]
};
export const buildSeriesBar = (datas, names, widths, colorIndexs = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]) => {
    let series = []
    for (let i = 0; i < datas.length; i++) {
        series.push({
            name: names[i],
            type: 'bar',
            data: datas[i],
            itemStyle: {
                barBorderRadius: 5,
                borderWidth: 1,
                borderType: 'solid',
                color: option.series[0].color[colorIndexs[i]],
                borderColor: '#82ff00',
                shadowColor: '#5470c6',
                shadowBlur: 3,
            },
            barWidth: widths[i]
        })
    }
    return series;
}
export const buildSeriesPie = (data, name) => {
    return [
        {
            name: name,
            type: 'pie',
            data: data
        }
    ]
}